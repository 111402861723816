<template lang="pug">
v-container(fill-height)
  v-row(justify="center")
    v-col(md="6")
      v-form(v-model="registerFormValid", @submit.prevent="userRegister")
        v-card(elevation="0")
          v-card-title
            .text-h4 {{ $t('auth.register.title') }}
          v-card-text
            .text-caption {{ $t('auth.register.desc') }}
            v-text-field(
              name="email",
              :label="$t('auth.form.email')",
              type="email",
              :rules="rules.email",
              v-model="email",
              prepend-icon="mdi-email"
            )
            v-text-field(
              name="password",
              :label="$t('auth.form.password')",
              type="password",
              :rules="rules.password",
              required,
              v-model="password",
              prepend-icon="mdi-lock"
            )
            v-text-field(
              name="password2",
              :label="$t('auth.form.passwordRepeat')",
              type="password",
              :rules="rules.password2",
              required,
              v-model="password2",
              prepend-icon="mdi-lock"
            )
          v-card-title 
            .text-h4 {{ $t('account.details.title') }}
          account-form(v-model="account")
          v-card-actions
            v-btn(text, @click="goLogin")
              v-icon(left) mdi-login
              | {{ $t('auth.btn.login') }}
            v-btn.ml-auto(
              color="primary",
              type="submit",
              :disabled="!registerFormValid"
            ) 
              v-icon(left) mdi-account-plus
              | {{ $t('auth.btn.register') }}
</template>
<script>
import AccountForm from "@/components/AccountForm";
import { validateEmail } from "@/plugins/utils";

export default {
  components: { AccountForm },
  data() {
    return {
      email: null,
      password: null,
      password2: null,
      account: {},
      registerFormValid: false,
      rules: {
        password: [(v) => !!v || this.$t("auth.rules.passwordNotEmpty")],
        password2: [
          (v) => !!v || this.$t("auth.rules.passwordNotEmpty"),
          (v) =>
            (!!v && this.password === this.password2) ||
            this.$t("auth.rules.passwordDoNotMatch"),
        ],
        email: [
          (v) => !!v || this.$t("auth.rules.emailNotEmpty"),
          (v) => !!validateEmail(v) || this.$t("auth.rules.emailNotValid"),
        ],
      },
    };
  },
  methods: {
    async userRegister() {
      let { email, password, account } = this;
      try {
        this.$store.dispatch("auth/registerEmail", {
          email,
          password,
          account,
        });
      } catch (error) {
        this.$snackbar({
          content: this.$t("auth.errors.registerFailed", { error }),
          color: "error",
        });
      }
    },
    goLogin() {
      this.$router.push({ name: "authLogin" });
    },
  },
};
</script>